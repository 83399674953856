<template>
  <div class="not-found-site">
    <main class="content">
      <section id="not-found">
        <div class="container">
          <div id="error-block">
            <h1 class="section-title">Page not found!</h1>
            <h2 class="intro-text">Sorry, but the page you were looking for could not be found.</h2>
            <p class="body-text">
              You can <router-link class="body-link" to="/">return to our front page</router-link>, or <router-link class="body-link" to="/contact">drop us a line</router-link> if you can't find what you're looking for.
            </p>
          </div>
        </div>
      </section>
    </main>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
  metaInfo: {
    title: 'Not Found'
  }
}
</script>

<style scoped>
#error-block {
    padding-top: 100px;
    padding-bottom: 125px;
    -webkit-box-flex: 1;
    -ms-flex: 1 0;
    flex: 1 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}
#error-block, .globalContent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

#error-block h1 {
    margin-bottom: 20px;
}
@media (min-width: 670px)
.section-title {
    font-size: 42px;
    line-height: 52px;
}
.section-title {
    font-weight: 400;
    font-size: 34px;
    line-height: 44px;
    color: #32325d;
}

#error-block h2 {
    margin-bottom: 30px;
}
@media (min-width: 670px)
.intro-text {
    font-size: 24px;
    line-height: 36px;
}
.intro-text {
    font-weight: 300;
    font-size: 21px;
    line-height: 32px;
    color: #424770;
}

.body-text {
    font-weight: 400;
    font-size: 17px;
    line-height: 28px;
    color: #525f7f;
}

.body-link {
    font-weight: 500;
    -webkit-transition: color .1s ease;
    transition: color .1s ease;
    cursor: pointer;
}
</style>
