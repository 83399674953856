import Vue from 'vue'
import Meta from 'vue-meta'
import Router from 'vue-router'
import PageNotFound from './views/PageNotFound'

Vue.use(Router)
Vue.use(Meta)

export default new Router({
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },

  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "about" */ './views/Base.vue'),
      children: [
        {
          path: '',
          name: 'Index',
          // redirect: '/index'

          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "index" */ './views/Index.vue'),
          meta: { title: 'Home' }
        },
        {
          path: '/contact',
          name: 'Contact',
          component: () => import(/* webpackChunkName: "contact" */ './views/Contact.vue'),
          meta: { title: 'Contact Us' }
        },
        {
          path: '/careers',
          name: 'Careers',
          component: () => import(/* webpackChunkName: "careers" */ './views/Careers.vue'),
          meta: { title: 'Careers' }
        },
        {
          path: '/ai',
          name: 'ai',
          component: () => import(/* webpackChunkName: "careers" */ './views/Ai.vue'),
          meta: { title: 'AI' }
        },
        {
          path: '*',
          name: 'PageNotFound',
          meta: { title: 'Page Not Found' },
          component: PageNotFound
        }
      ]
    }
  ]
})
